// import './styles.css'
import {
    Container,
    IconButtonLeft,
    IconButtonRight,
    SliderItem,
} from './styles'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import compressedImage from '@/helpers/compressedImage'
import { EventTracking } from '@/helpers/eventTracking'
import * as homeSelector from '@/store/selectors/home'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const HomeCarousel = ({ banner }) => {
    const sliderRef = useRef(null)

    const goToPrevious = () => {
        sliderRef.current.slickPrev()
    }

    const goToNext = () => {
        sliderRef.current.slickNext()
    }

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        // slidesToShow: 3,
        slidesToScroll: 1,
        // initialSlide: 1,
        variableWidth: true,
        // centerMode:true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    // slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    // slidesToShow: 1.7,
                    slidesToScroll: 1.0,
                    infinite: false,
                },
            },

            {
                breakpoint: 1280,
                settings: {
                    // slidesToShow: 2.6,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
        ],
    }
    const history = useRouter()

    const currentPage = history.pathname

    const activeTab = useSelector(homeSelector._activeTab)

    const { data: session } = useSession()

    const OnBannerClick = (entityType) => {
        let eventName = ''
        if (activeTab === 'Buat Kamu' && currentPage === '/') {
            eventName = 'foryou_banner_clicked'
        } else if (activeTab === 'Podcast' && currentPage === '/podcast') {
            eventName = 'podcast_banner_clicked'
        } else if (activeTab === 'Audiobook' && currentPage === '/audiobook') {
            eventName = 'audiobook_banner_clicked'
        } else if (activeTab === 'Series' && currentPage === '/audioseries') {
            eventName = 'audioseries_banner_clicked'
        }
        eventName && session?.token && EventTracking(eventName, {}, session)

        if (entityType === 'link') {
            if (session?.token) {
                EventTracking('banner_ad_clicked', 'bannerAdLink', session)

                AmplitudeEventTracking(
                    'banner ad clicked',
                    'banner ad link',
                    session
                )
            }
        }

        // history.push(`/${entityType}/${entityValue}`)
    }
    return (
        <Container
            style={{
                minWidth: 0,
                minHeight: 0,
                position: 'relative',
            }}
        >
            <Slider ref={sliderRef} {...settings}>
                {banner &&
                    banner.length &&
                    banner.map((item, index) => {
                        const {
                            image,
                            imageMeta,
                            entityType,
                            id,
                            entityValue,
                            type,
                            entitySubType,
                        } = item

                        return (
                            <div
                                key={id}
                                onClick={() =>
                                    OnBannerClick(entityType, entityValue)
                                }
                            >
                                <Link
                                    href={
                                        entityType === 'collection' &&
                                        entitySubType === 'themepage'
                                            ? `/themepage/${entityValue}`
                                            : entityType === 'link'
                                            ? entityValue
                                            : `/${entityType}/${entityValue}`
                                    }
                                    key={id}
                                    passHref
                                    prefetch={false}
                                >
                                    <a
                                        target={
                                            entityType === 'link' &&
                                            type === 'external'
                                                ? '_blank'
                                                : '_self'
                                        }
                                    >
                                        <SliderItem
                                            loading={
                                                index > 2 ? 'lazy' : 'eager'
                                            }
                                            src={compressedImage(
                                                image,
                                                imageMeta,
                                                true
                                            )}
                                            alt="slider"
                                        />
                                    </a>
                                </Link>
                            </div>
                        )
                    })}
            </Slider>

            <IconButtonLeft onClick={goToPrevious}>
                <img src="/img/slider-nav-left.svg" alt="slider nav left" />
            </IconButtonLeft>

            <IconButtonRight onClick={goToNext}>
                <img src="/img/slider-nav-right.svg" alt="slider nav right" />
            </IconButtonRight>
        </Container>
    )
}

export default HomeCarousel
