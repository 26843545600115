import { ColumnFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    width: 100%;
    margin: 0 auto 5px;
    padding: 20px;

    @media (min-width: 500px) {
        button {
            display: none;
            opacity: 0.7;
        }

        &:hover button {
            display: block;
        }

        button:hover {
            opacity: 1;
        }
    }
`
export const SliderItem = styled.img`
    cursor: pointer;
    border-radius: 10px;
    // width: 100%;
    height: 128px;
    object-fit: cover;
    border-radius: 8px;

    @media (min-width: 600px) {
        height: 140px;
    }
    @media (min-width: 905px) {
        height: 166px;
    }
    @media (min-width: 1280px) {
        height: 166px;
    }
    @media (min-width: 1920px) {
        height: 216px;
    }
    @media (min-width: 2040px) {
        // height: 100%;
    }
`

export const IconButtonLeft = styled.button`
    border: none;
    background: none;
    width: max-content;
    padding: 0px;

    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(60%, -50%);

    @media (max-width: 500px) {
        left: 12px;
        width: 30px;
        height: 30px;
    }
`

export const IconButtonRight = styled.button`
    border: none;
    background: none;
    width: max-content;
    padding: 0px;

    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(-60%, -50%);

    @media (max-width: 500px) {
        right: 12px;
        width: 30px;
        height: 30px;
    }
`
